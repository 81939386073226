import { useState, useEffect, useCallback } from 'react';
import { 
  User as FirebaseUser,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  getAuth
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface User {
  uid: string;
  email: string | null;
  username: string | null;
  emailVerified: boolean;
}

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
          setUser({
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            username: userDoc.exists() ? userDoc.data().username : firebaseUser.displayName,
            emailVerified: firebaseUser.emailVerified
          });
        } catch (err) {
          console.error('Error fetching user data:', err);
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const register = useCallback(async (email: string, password: string, username: string) => {
    try {
      setError(null);
      const { user: firebaseUser } = await createUserWithEmailAndPassword(auth, email, password);
      
      await updateProfile(firebaseUser, {
        displayName: username
      });

      await sendEmailVerification(firebaseUser);

      await setDoc(doc(db, 'users', firebaseUser.uid), {
        username,
        email,
        credits: 0,
        createdAt: serverTimestamp(),
        emailVerified: false
      });

      setUser({
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        username,
        emailVerified: firebaseUser.emailVerified
      });

      return { 
        success: true,
        message: 'Please check your email to verify your account'
      };
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Registration failed';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    }
  }, [auth]);

  const login = useCallback(async (email: string, password: string) => {
    try {
      setError(null);
      const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
      const userDocRef = doc(db, 'users', firebaseUser.uid);
      const userDoc = await getDoc(userDocRef);
      
      if (firebaseUser.emailVerified && userDoc.exists() && !userDoc.data().emailVerified) {
        await updateDoc(userDocRef, {
          emailVerified: true
        });
      }

      setUser({
        uid: firebaseUser.uid,
        email: firebaseUser.email,
        username: userDoc.exists() ? userDoc.data().username : firebaseUser.displayName,
        emailVerified: firebaseUser.emailVerified
      });

      if (!firebaseUser.emailVerified) {
        return { 
          success: true,
          warning: 'Please verify your email address to access all features'
        };
      }

      return { success: true };
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Login failed';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    }
  }, [auth]);

  const logout = useCallback(async () => {
    try {
      setError(null);
      await firebaseSignOut(auth);
      setUser(null);
      return { success: true };
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Logout failed';
      setError(errorMessage);
      return { success: false, error: errorMessage };
    }
  }, [auth]);

  const resendVerificationEmail = useCallback(async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error('No authenticated user');
      
      await sendEmailVerification(currentUser);
      return { 
        success: true,
        message: 'Verification email sent. Please check your inbox.'
      };
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to send verification email';
      return { success: false, error: errorMessage };
    }
  }, [auth]);

  const resetPassword = useCallback(async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      return { 
        success: true,
        message: 'Password reset email sent. Please check your inbox.'
      };
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to send reset email';
      return { success: false, error: errorMessage };
    }
  }, [auth]);

  return { 
    user,
    isLoading,
    error,
    register,
    login,
    logout,
    resendVerificationEmail,
    resetPassword
  };
};

export default useAuth;