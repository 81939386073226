export const STRIPE_CONFIG = {
  publishableKey: 'pk_live_51QIeRiGpqdcCYOxg8X5DuDyMFyYOriGCkYVFAB2xSdf6lj2HVdz22iei2a5wLgBl03igmoqmfSLz8BwLwsvEaBc100aKUzPuPL',
  buttons: {
    starter: {
      id: 'buy_btn_1QLXjVGpqdcCYOxgUYY9ufCS',
      credits: 10,
      outputs: 5,
      price: 8,
      description: 'Perfect for trying out the service'
    },
    basic: {
      id: 'buy_btn_1QLXj0GpqdcCYOxgRjGVUHSW',
      credits: 20,
      outputs: 10,
      price: 15,
      description: 'Great for occasional use'
    },
    pro: {
      id: 'buy_btn_1QLXbYGpqdcCYOxgV5u4m02q',
      credits: 40,
      outputs: 20,
      price: 30,
      popular: true,
      description: 'Most popular choice for regular users'
    },
    business: {
      id: 'buy_btn_1QLXbYGpqdcCYOxgV5u4m02q',
      credits: 80,
      outputs: 40,
      price: 60,
      description: 'Ideal for business users'
    }
  }
};