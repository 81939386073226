import { initializeApp, FirebaseOptions } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getAnalytics, isSupported } from 'firebase/analytics';

// Enhanced validation with detailed error messages
const validateConfig = (config: FirebaseOptions): { isValid: boolean; error?: string } => {
  const requiredFields: (keyof FirebaseOptions)[] = [
    'apiKey',
    'authDomain',
    'projectId',
    'storageBucket',
    'messagingSenderId',
    'appId'
  ];

  for (const field of requiredFields) {
    const value = config[field];
    if (!value || typeof value !== 'string' || value.trim() === '') {
      return { 
        isValid: false, 
        error: `Missing or invalid Firebase ${field}. Please check your environment variables.`
      };
    }
  }

  return { isValid: true };
};

const getFirebaseConfig = (): FirebaseOptions => {
  const config = {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
  };

  const validation = validateConfig(config);
  if (!validation.isValid) {
    throw new Error(validation.error);
  }

  return config;
};

// Initialize Firebase with better error handling
const initializeFirebase = () => {
  try {
    const config = getFirebaseConfig();
    const app = initializeApp(config);
    const db = getFirestore(app);
    const auth = getAuth(app);
    let analytics = null;

    // Initialize Analytics only if supported and measurement ID is provided
    if (config.measurementId) {
      isSupported().then(supported => {
        if (supported) {
          analytics = getAnalytics(app);
        }
      }).catch(console.error);
    }

    return { app, db, auth, analytics, error: null };
  } catch (error) {
    console.error('Firebase initialization error:', error);
    return {
      app: null,
      db: null,
      auth: null,
      analytics: null,
      error: error instanceof Error ? error.message : 'Failed to initialize Firebase'
    };
  }
};

const firebase = initializeFirebase();

// Export initialized services and error state
export const app = firebase.app;
export const db = firebase.db;
export const auth = firebase.auth;
export const analytics = firebase.analytics;
export const firebaseError = firebase.error;

// Export a helper to check if Firebase is properly initialized
export const isFirebaseInitialized = () => {
  return app !== null && db !== null && auth !== null;
};