import React from 'react';
import useAuth from '../hooks/useAuth';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-buy-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
        'buy-button-id': string;
        'publishable-key': string;
        'client-reference-id'?: string;
      };
    }
  }
}

interface StripeBuyButtonProps {
  buttonId: string;
  publishableKey: string;
  className?: string;
}

const StripeBuyButton: React.FC<StripeBuyButtonProps> = ({ buttonId, publishableKey, className }) => {
  const { user } = useAuth();

  if (!user || !user.emailVerified) {
    return null;
  }

  return (
    <div className={className}>
      <stripe-buy-button
        buy-button-id={buttonId}
        publishable-key={publishableKey}
        client-reference-id={user.uid}
      />
    </div>
  );
};

export default StripeBuyButton;