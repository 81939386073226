import React from 'react';
import { WifiOff } from 'lucide-react';

const OfflineAlert: React.FC<{ isOnline: boolean }> = ({ isOnline }) => {
  if (isOnline) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg flex items-center space-x-2">
      <WifiOff className="w-5 h-5" />
      <span>You're offline. Some features may be unavailable.</span>
    </div>
  );
};

export default OfflineAlert;