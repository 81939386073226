import React, { useState } from 'react';
import { AlertTriangle, Mail, X } from 'lucide-react';
import useAuth from '../hooks/useAuth';

const EmailVerificationBanner = () => {
  const { user, resendVerificationEmail } = useAuth();
  const [isVisible, setIsVisible] = useState(true);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  if (!user || user.emailVerified || !isVisible) return null;

  const handleResend = async () => {
    setIsLoading(true);
    const result = await resendVerificationEmail();
    setIsLoading(false);

    if (result.success) {
      setMessage(result.message || 'Verification email sent');
    } else {
      setMessage(result.error || 'Failed to send verification email');
    }

    // Clear message after 5 seconds
    setTimeout(() => setMessage(''), 5000);
  };

  return (
    <div className="bg-amber-50 border-b border-amber-200">
      <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex items-center">
            <AlertTriangle className="h-5 w-5 text-amber-500" />
            <p className="ml-3 font-medium text-amber-700">
              Please verify your email address to access all features
            </p>
          </div>
          <div className="flex items-center space-x-4">
            {message && (
              <span className="text-sm text-amber-600">
                {message}
              </span>
            )}
            <button
              onClick={handleResend}
              disabled={isLoading}
              className="flex items-center px-4 py-1 rounded-md text-sm font-medium text-amber-700 bg-amber-100 hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Mail className="h-4 w-4 mr-2" />
              {isLoading ? 'Sending...' : 'Resend Email'}
            </button>
            <button
              onClick={() => setIsVisible(false)}
              className="p-1 rounded-md hover:bg-amber-100"
            >
              <X className="h-5 w-5 text-amber-500" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationBanner;