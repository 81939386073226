import React from 'react';
import { Download, Loader2, Volume2 } from 'lucide-react';
import type { SeparatedAudioResult } from '../services/assemblyAI';

interface AudioSeparationResultProps {
  result: SeparatedAudioResult;
  originalAudio: File;
  isProcessing?: boolean;
}

const AudioSeparationResult: React.FC<AudioSeparationResultProps> = ({
  result,
  originalAudio,
  isProcessing,
}) => {
  const processAudioSegments = async (speakerSegments: SeparatedAudioResult['audioSegments'][0]) => {
    try {
      // Create AudioContext
      const audioContext = new AudioContext();
      
      // Load the original audio file
      const arrayBuffer = await originalAudio.arrayBuffer();
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      
      // Create a new buffer for the isolated track
      const isolatedBuffer = audioContext.createBuffer(
        audioBuffer.numberOfChannels,
        audioBuffer.length,
        audioBuffer.sampleRate
      );

      // Get the audio data
      const inputData = audioBuffer.getChannelData(0);
      const outputData = isolatedBuffer.getChannelData(0);
      
      // Clear the output buffer
      outputData.fill(0);
      
      // Copy segments for the current speaker
      speakerSegments.segments.forEach(segment => {
        const startSample = Math.floor(segment.start * audioBuffer.sampleRate / 1000);
        const endSample = Math.floor(segment.end * audioBuffer.sampleRate / 1000);
        
        for (let i = startSample; i < endSample && i < inputData.length; i++) {
          outputData[i] = inputData[i];
        }
      });
      
      // Convert to WAV
      const wavBuffer = audioBufferToWav(isolatedBuffer);
      const blob = new Blob([wavBuffer], { type: 'audio/wav' });
      
      // Create download link
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `speaker_${speakerSegments.speaker}_isolated.wav`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error processing audio:', error);
      alert('Error processing audio. Please try again.');
    }
  };

  // Helper function to convert AudioBuffer to WAV format
  const audioBufferToWav = (buffer: AudioBuffer) => {
    const numOfChan = buffer.numberOfChannels;
    const length = buffer.length * numOfChan * 2;
    const buffer32 = new Float32Array(buffer.length * numOfChan);
    const view = new DataView(new ArrayBuffer(44 + length));
    const channels = [];
    let offset = 0;
    let pos = 0;

    // Get channels
    for (let i = 0; i < numOfChan; i++) {
      channels.push(buffer.getChannelData(i));
    }

    // Interleave channels
    for (let i = 0; i < buffer.length; i++) {
      for (let j = 0; j < numOfChan; j++) {
        buffer32[offset++] = channels[j][i];
      }
    }

    // Write WAV header
    setUint32(0x46464952);                         // "RIFF"
    setUint32(36 + length);                        // file length
    setUint32(0x45564157);                         // "WAVE"
    setUint32(0x20746D66);                         // "fmt " chunk
    setUint32(16);                                 // length = 16
    setUint16(1);                                  // PCM (uncompressed)
    setUint16(numOfChan);
    setUint32(buffer.sampleRate);
    setUint32(buffer.sampleRate * 2 * numOfChan);  // avg. bytes/sec
    setUint16(numOfChan * 2);                      // block-align
    setUint16(16);                                 // 16-bit
    setUint32(0x61746164);                         // "data" - chunk
    setUint32(length);                             // chunk length

    // Write interleaved data
    for (let i = 0; i < buffer32.length; i++) {
      setInt16(buffer32[i] * 0x7FFF);
    }

    function setUint16(data: number) {
      view.setUint16(pos, data, true);
      pos += 2;
    }

    function setUint32(data: number) {
      view.setUint32(pos, data, true);
      pos += 4;
    }

    function setInt16(data: number) {
      view.setInt16(pos, data, true);
      pos += 2;
    }

    return new Uint8Array(view.buffer);
  };

  return (
    <div className="mt-8 space-y-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-xl font-semibold text-gray-900 mb-4">Separated Audio Tracks</h2>
        
        {isProcessing ? (
          <div className="flex items-center space-x-2 text-gray-600">
            <Loader2 className="w-5 h-5 animate-spin" />
            <span>Processing audio separation...</span>
          </div>
        ) : (
          <div className="space-y-4">
            {result.audioSegments.map((speakerSegments) => (
              <div
                key={speakerSegments.speaker}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
              >
                <div className="flex items-center space-x-3">
                  <Volume2 className="w-5 h-5 text-indigo-500" />
                  <span className="font-medium text-gray-900">
                    Speaker {speakerSegments.speaker}
                  </span>
                  <span className="text-sm text-gray-500">
                    ({(speakerSegments.duration / 1000).toFixed(1)}s)
                  </span>
                </div>
                <button
                  onClick={() => processAudioSegments(speakerSegments)}
                  className="flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <Download className="w-4 h-4 mr-2" />
                  Download Track
                </button>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold text-gray-900">Transcript</h2>
          <button
            onClick={() => {
              const blob = new Blob([result.transcript], { type: 'text/plain' });
              const url = URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url;
              link.download = 'transcript.txt';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(url);
            }}
            className="flex items-center px-3 py-1 text-sm text-indigo-600 hover:text-indigo-700 hover:bg-indigo-50 rounded-md transition-colors duration-200"
          >
            <Download className="w-4 h-4 mr-1" />
            Download
          </button>
        </div>
        <div className="bg-gray-50 rounded-md p-4">
          <pre className="whitespace-pre-wrap text-sm text-gray-700 font-mono">
            {result.transcript}
          </pre>
        </div>
      </div>
    </div>
  );
};

export default AudioSeparationResult;