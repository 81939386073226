import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Coins, AlertCircle } from 'lucide-react';

interface CreditMonitorProps {
  credits: number;
}

const CreditMonitor: React.FC<CreditMonitorProps> = ({ credits }) => {
  const navigate = useNavigate();
  const isLowCredits = credits < 5;

  return (
    <div className="flex items-center space-x-3">
      <div className="flex items-center bg-white rounded-full px-3 py-1 shadow-sm border border-gray-200">
        <Coins className="w-4 h-4 text-indigo-500 mr-1.5" />
        <span className="text-sm font-medium text-gray-700">
          {credits} Credit{credits !== 1 ? 's' : ''}
        </span>
      </div>
      
      {isLowCredits && (
        <div className="relative group">
          <button
            onClick={() => navigate('/credits')}
            className="flex items-center text-amber-600 bg-amber-50 rounded-full px-3 py-1 hover:bg-amber-100 transition-colors"
          >
            <AlertCircle className="w-4 h-4 mr-1.5" />
            <span className="text-sm font-medium">Low Credits</span>
          </button>
          
          <div className="absolute right-0 top-full mt-2 w-48 bg-white rounded-lg shadow-lg py-2 px-3 text-sm text-gray-600 invisible group-hover:visible transition-all opacity-0 group-hover:opacity-100 z-50">
            Running low on credits! Add more to continue using audio separation.
          </div>
        </div>
      )}
    </div>
  );
};

export default CreditMonitor;