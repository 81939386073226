import React, { useState } from 'react';
import { ChevronDown, ChevronUp, HelpCircle } from 'lucide-react';

interface FAQItem {
  question: string;
  answer: string;
}

const faqs: FAQItem[] = [
  {
    question: "Do you charge a monthly membership fee?",
    answer: "NO! We offer credits you can use as needed. Pay once, use anytime, and they never expire!"
  },
  {
    question: "Do my points roll over every month?",
    answer: "Yes, Points don't expire whatever you don't use will remain in your account at all times."
  },
  {
    question: "What type of audio files can it work on?",
    answer: "Our Audio Separation tool is ideal for Podcasts or Interview style audio clips only with primarily two speakers. For best results we do not recommend uploading audio files with more than two speakers engaging."
  },
  {
    question: "Do you offer transcription or diarization features also?",
    answer: "Yes, our AI tool will create a downloadable .txt transcript separated by speaker (also known as Diarziation)."
  },
  {
    question: "What is the upload file size limit?",
    answer: "We limit uploads to audio files no more than 100MB limit. Also we recommend audio clips be no longer than 35 minutes long for best results."
  },
  {
    question: "Does your App work with Audio Overview Files from Notebook LM?",
    answer: "Yes, it works very well with Notebook LM audio overview generated podcast audio files. See our case studies and demo tutorials."
  },
  {
    question: "Do you offer a refund?",
    answer: "No, we cannot offer refund for used/unused credits at the moment."
  },
  {
    question: "Why do you charge a fee?",
    answer: "AI apps are resource heavy and require expensive paid APIs as well as Server costs. We also wanted to exclude advertising from our website to give or members a better user experience."
  }
];

const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-12">
          <HelpCircle className="w-16 h-16 text-indigo-600 mx-auto mb-4" />
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h1>
          <p className="text-lg text-gray-600">
            Find answers to common questions about SpeakerSplit
          </p>
        </div>

        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm overflow-hidden"
            >
              <button
                onClick={() => toggleFAQ(index)}
                className="w-full px-6 py-4 flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-inset"
              >
                <span className="text-left font-medium text-gray-900">
                  {faq.question}
                </span>
                {openIndex === index ? (
                  <ChevronUp className="w-5 h-5 text-indigo-500" />
                ) : (
                  <ChevronDown className="w-5 h-5 text-gray-400" />
                )}
              </button>
              
              <div
                className={`px-6 overflow-hidden transition-all duration-200 ease-in-out ${
                  openIndex === index
                    ? 'max-h-96 py-4 opacity-100'
                    : 'max-h-0 py-0 opacity-0'
                }`}
              >
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-gray-600">
            Still have questions?{' '}
            <a href="mailto:support@speakersplit.com" className="text-indigo-600 hover:text-indigo-500">
              Contact our support team
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FAQPage;