import { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, onSnapshot, serverTimestamp, collection, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import useAuth from './useAuth';

export const useCredits = () => {
  const [credits, setCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (!user) {
      setCredits(0);
      setIsLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists()) {
        setCredits(doc.data().credits || 0);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const addCredits = async (amount: number) => {
    if (!user) return { success: false, error: 'User not authenticated' };

    try {
      // Create transaction document first
      const transactionRef = await addDoc(collection(db, 'creditTransactions'), {
        userId: user.uid,
        amount: amount,
        type: 'add',
        timestamp: serverTimestamp(),
      });

      // Then update user credits
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        credits: credits + amount,
        lastUpdated: serverTimestamp()
      });

      return { success: true };
    } catch (error) {
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Failed to add credits' 
      };
    }
  };

  const useCredits = async (amount: number) => {
    if (!user) return { success: false, error: 'User not authenticated' };

    try {
      if (credits < amount) {
        return { success: false, error: 'Insufficient credits' };
      }

      // Create transaction document first
      const transactionRef = await addDoc(collection(db, 'creditTransactions'), {
        userId: user.uid,
        amount: amount,
        type: 'deduct',
        timestamp: serverTimestamp(),
      });

      // Then update user credits
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        credits: credits - amount,
        lastUpdated: serverTimestamp()
      });

      return { success: true };
    } catch (error) {
      return { 
        success: false, 
        error: error instanceof Error ? error.message : 'Failed to use credits' 
      };
    }
  };

  return { 
    credits, 
    isLoading,
    addCredits,
    useCredits
  };
};

export default useCredits;