import React from 'react';

const Logo = () => {
  return (
    <svg viewBox="0 0 697 593" className="w-full h-full">
      <g>
        {/* Left speaker */}
        <g>
          <rect x="100" y="120" width="200" height="300" rx="20" fill="black"/>
          <circle cx="200" cy="200" r="50" fill="#6366F1"/>
          <circle cx="200" cy="200" r="20" fill="black"/>
          <rect x="150" y="300" width="100" height="60" rx="10" fill="#6366F1"/>
        </g>

        {/* Right speaker */}
        <g>
          <rect x="397" y="120" width="200" height="300" rx="20" fill="#6366F1"/>
          <circle cx="497" cy="200" r="50" fill="black"/>
          <circle cx="497" cy="200" r="20" fill="#6366F1"/>
          <rect x="447" y="300" width="100" height="60" rx="10" fill="black"/>
        </g>

        {/* Sound waves */}
        <g>
          {/* Left waves */}
          <rect x="50" y="180" width="30" height="10" rx="5" fill="black"/>
          <rect x="50" y="220" width="30" height="10" rx="5" fill="black"/>
          <rect x="50" y="260" width="30" height="10" rx="5" fill="black"/>
          
          {/* Right waves */}
          <rect x="617" y="180" width="30" height="10" rx="5" fill="#6366F1"/>
          <rect x="617" y="220" width="30" height="10" rx="5" fill="#6366F1"/>
          <rect x="617" y="260" width="30" height="10" rx="5" fill="#6366F1"/>
        </g>
      </g>
    </svg>
  );
};

export default Logo;