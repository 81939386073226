import axios from 'axios';

// Create a function to validate the API key
const validateApiKey = () => {
  const apiKey = import.meta.env.VITE_ASSEMBLY_AI_API_KEY;
  if (!apiKey || apiKey === 'your_assemblyai_api_key_here') {
    console.warn('AssemblyAI API key is not configured. Audio processing features will be disabled.');
    return null;
  }
  return apiKey;
};

const baseURL = 'https://api.assemblyai.com/v2';

const assemblyAI = axios.create({
  baseURL,
  headers: {
    'Authorization': validateApiKey() || '',
    'Content-Type': 'application/json',
  },
});

interface TranscriptionResponse {
  id: string;
  status: 'queued' | 'processing' | 'completed' | 'error';
  text?: string;
  error?: string;
  utterances?: Array<{
    speaker: string;
    text: string;
    start: number;
    end: number;
  }>;
  audio_duration?: number;
}

interface AudioConfig {
  audio_url: string;
  speaker_labels?: boolean;
  auto_chapters?: boolean;
  auto_highlights?: boolean;
  content_safety?: boolean;
  language_code?: string;
  speech_threshold?: number;
  summarization?: boolean;
  summary_model?: string;
  summary_type?: string;
  audio_start_from?: number;
  audio_end_at?: number;
  word_boost?: string[];
}

interface AudioSegment {
  speaker: string;
  start: number;
  end: number;
}

export const uploadAudio = async (file: File): Promise<string> => {
  const apiKey = validateApiKey();
  if (!apiKey) {
    throw new Error('Audio processing is currently disabled due to missing API key');
  }
  
  try {
    const buffer = await file.arrayBuffer();
    
    const uploadResponse = await axios.post(
      `${baseURL}/upload`,
      buffer,
      {
        headers: {
          'Authorization': apiKey,
          'Content-Type': 'application/octet-stream',
          'Transfer-Encoding': 'chunked',
        },
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
      }
    );

    if (!uploadResponse.data?.upload_url) {
      throw new Error('Upload failed: No upload URL received');
    }

    return uploadResponse.data.upload_url;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        throw new Error('Upload failed: Invalid API key. Please check your AssemblyAI API key.');
      }
      throw new Error(`Upload failed: ${error.response?.data?.error || error.message}`);
    }
    throw error;
  }
};

export const transcribeAudio = async (audioUrl: string, config: Partial<AudioConfig> = {}): Promise<string> => {
  const apiKey = validateApiKey();
  if (!apiKey) {
    throw new Error('Audio processing is currently disabled due to missing API key');
  }
  
  try {
    const transcriptionResponse = await assemblyAI.post('/transcript', {
      audio_url: audioUrl,
      ...config,
    });

    const transcriptId = transcriptionResponse.data.id;
    let retries = 0;
    const maxRetries = 60;

    while (retries < maxRetries) {
      const pollingResponse = await assemblyAI.get<TranscriptionResponse>(`/transcript/${transcriptId}`);
      const { status, text, error } = pollingResponse.data;

      if (status === 'completed' && text) {
        return text;
      }

      if (status === 'error') {
        throw new Error(error || 'Transcription failed');
      }

      retries++;
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    throw new Error('Transcription timed out after 2 minutes');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        throw new Error('Transcription failed: Invalid API key');
      }
      throw new Error(`Transcription failed: ${error.response?.data?.error || error.message}`);
    }
    throw error;
  }
};

export interface SeparatedAudioResult {
  transcript: string;
  audioSegments: {
    speaker: string;
    segments: AudioSegment[];
    duration: number;
  }[];
}

export const separateAudio = async (audioUrl: string): Promise<SeparatedAudioResult> => {
  const apiKey = validateApiKey();
  if (!apiKey) {
    throw new Error('Audio processing is currently disabled due to missing API key');
  }
  
  try {
    const response = await assemblyAI.post('/transcript', {
      audio_url: audioUrl,
      speaker_labels: true,
      speakers_expected: 2,
    });

    const transcriptId = response.data.id;
    let retries = 0;
    const maxRetries = 60;

    while (retries < maxRetries) {
      const pollingResponse = await assemblyAI.get<TranscriptionResponse>(`/transcript/${transcriptId}`);
      const { status, utterances, error, audio_duration } = pollingResponse.data;

      if (status === 'completed' && utterances && audio_duration) {
        // Group segments by speaker
        const speakerSegments: { [key: string]: AudioSegment[] } = {};
        
        utterances.forEach(utterance => {
          if (!speakerSegments[utterance.speaker]) {
            speakerSegments[utterance.speaker] = [];
          }
          speakerSegments[utterance.speaker].push({
            speaker: utterance.speaker,
            start: utterance.start,
            end: utterance.end,
          });
        });

        // Format transcript
        const transcript = utterances
          .map(u => `[Speaker ${u.speaker}]: ${u.text}`)
          .join('\n\n');

        // Convert to array format
        const audioSegments = Object.entries(speakerSegments).map(([speaker, segments]) => ({
          speaker,
          segments,
          duration: audio_duration,
        }));

        return {
          transcript,
          audioSegments,
        };
      }

      if (status === 'error') {
        throw new Error(error || 'Audio separation failed');
      }

      retries++;
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    throw new Error('Audio separation timed out after 2 minutes');
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 401) {
        throw new Error('Audio separation failed: Invalid API key');
      }
      throw new Error(`Audio separation failed: ${error.response?.data?.error || error.message}`);
    }
    throw error;
  }
};