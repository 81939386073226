import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CheckCircle, Loader2 } from 'lucide-react';
import useAuth from '../hooks/useAuth';

const CreditsSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isVerifying, setIsVerifying] = useState(true);
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    const verifyPayment = async () => {
      if (!sessionId || !user) {
        navigate('/credits');
        return;
      }

      try {
        const apiUrl = import.meta.env.VITE_API_URL || 'http://localhost:3000/api';
        const response = await fetch(`${apiUrl}/verify-session/${sessionId}`);
        
        if (!response.ok) {
          throw new Error('Payment verification failed');
        }

        // Wait for 3 seconds to allow webhook processing
        await new Promise(resolve => setTimeout(resolve, 3000));
        setIsVerifying(false);

        // Redirect to credits page after 2 more seconds
        setTimeout(() => {
          navigate('/credits');
        }, 2000);
      } catch (error) {
        console.error('Error verifying payment:', error);
        navigate('/credits');
      }
    };

    verifyPayment();
  }, [sessionId, user, navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
        {isVerifying ? (
          <>
            <Loader2 className="w-16 h-16 text-indigo-500 animate-spin mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Processing Payment
            </h2>
            <p className="text-gray-600">
              Please wait while we verify your payment and add credits to your account...
            </p>
          </>
        ) : (
          <>
            <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold text-gray-900 mb-2">
              Payment Successful!
            </h2>
            <p className="text-gray-600">
              Your credits have been added to your account. Redirecting you back...
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditsSuccessPage;