import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Clock, 
  DollarSign, 
  Wand2,
  FileAudio,
  Waves,
  Users,
  Download,
  Sparkles,
  ArrowRight
} from 'lucide-react';

const AboutPage = () => {
  const navigate = useNavigate();

  const features = [
    {
      icon: <Clock className="w-6 h-6" />,
      title: "Save Time",
      description: "Process audio files in minutes instead of hours of manual editing"
    },
    {
      icon: <DollarSign className="w-6 h-6" />,
      title: "Reduce Costs",
      description: "Eliminate expensive audio editing software and professional services"
    },
    {
      icon: <Wand2 className="w-6 h-6" />,
      title: "AI-Powered",
      description: "Advanced machine learning algorithms ensure accurate speaker separation"
    },
    {
      icon: <FileAudio className="w-6 h-6" />,
      title: "Multiple Formats",
      description: "Support for MP3, WAV, M4A, and FLAC audio files"
    }
  ];

  const benefits = [
    {
      icon: <Waves className="w-12 h-12 text-indigo-500" />,
      title: "Clean Audio Separation",
      description: "Our AI precisely identifies and separates different speakers, maintaining audio quality"
    },
    {
      icon: <Users className="w-12 h-12 text-indigo-500" />,
      title: "Speaker Recognition",
      description: "Automatically detect and label different speakers in your recordings"
    },
    {
      icon: <Download className="w-12 h-12 text-indigo-500" />,
      title: "Individual Tracks",
      description: "Download separate audio files for each speaker for easy editing"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
          <div className="text-center">
            <h1 className="text-5xl font-bold text-gray-900 mb-6">
              Transform Your Audio Editing Workflow
            </h1>
            <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
              SpeakerSplit uses advanced AI to automatically separate different speakers in your audio files,
              saving you hours of manual editing work.
            </p>
            <button
              onClick={() => navigate('/register')}
              className="inline-flex items-center px-8 py-4 bg-indigo-600 text-white rounded-lg text-lg font-semibold hover:bg-indigo-700 transition-colors"
            >
              Get Started Free
              <ArrowRight className="ml-2 w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow"
            >
              <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center text-indigo-600 mb-4">
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Benefits Section */}
      <div className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Why Choose SpeakerSplit?
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our powerful AI technology makes audio editing easier than ever before
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {benefits.map((benefit, index) => (
              <div key={index} className="text-center">
                <div className="flex justify-center mb-6">
                  {benefit.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {benefit.title}
                </h3>
                <p className="text-gray-600">
                  {benefit.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-indigo-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="text-white mb-8 md:mb-0">
              <h2 className="text-3xl font-bold mb-4">
                Ready to Transform Your Audio?
              </h2>
              <p className="text-indigo-100 text-lg">
                Join thousands of content creators who trust SpeakerSplit
              </p>
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => navigate('/register')}
                className="px-8 py-4 bg-white text-indigo-600 rounded-lg font-semibold hover:bg-indigo-50 transition-colors"
              >
                Start Free Trial
              </button>
              <button
                onClick={() => navigate('/about')}
                className="px-8 py-4 bg-indigo-500 text-white rounded-lg font-semibold hover:bg-indigo-400 transition-colors"
              >
                Watch Tutorial
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;