import React, { useState } from 'react';
import { Shield, Zap, Sparkles } from 'lucide-react';
import { STRIPE_CONFIG } from '../config/stripe';
import StripeBuyButton from '../components/StripeBuyButton';
import useAuth from '../hooks/useAuth';

const CreditsPage = () => {
  const { user } = useAuth();
  const [error, setError] = useState('');

  const features = [
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Secure Processing",
      description: "Your audio files are processed securely and deleted after completion"
    },
    {
      icon: <Zap className="w-6 h-6" />,
      title: "Fast Results",
      description: "Get your separated audio tracks in minutes, not hours"
    },
    {
      icon: <Sparkles className="w-6 h-6" />,
      title: "High Quality",
      description: "Advanced AI ensures clean separation with minimal artifacts"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            Choose Your Credit Package
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Get credits to process your audio files. Each audio separation uses 2 credits.
          </p>
        </div>

        {error && (
          <div className="max-w-md mx-auto mb-8 p-4 bg-red-50 border border-red-200 rounded-lg text-red-700">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-16">
          {Object.entries(STRIPE_CONFIG.buttons).map(([key, pkg]) => (
            <div
              key={key}
              className={`relative bg-white rounded-2xl shadow-lg overflow-hidden transition-transform hover:scale-105 ${
                pkg.popular ? 'ring-2 ring-indigo-500' : ''
              }`}
            >
              {pkg.popular && (
                <div className="absolute top-0 right-0 bg-indigo-500 text-white px-3 py-1 text-sm font-medium rounded-bl-lg">
                  Popular
                </div>
              )}
              
              <div className="p-6">
                <div className="mb-4">
                  <h3 className="text-2xl font-bold text-gray-900">
                    {pkg.credits} Credits
                  </h3>
                  <p className="text-gray-500 text-sm">
                    {pkg.outputs} audio separations
                  </p>
                </div>

                <div className="mb-6">
                  <p className="text-3xl font-bold text-gray-900">
                    ${pkg.price}
                  </p>
                  <p className="text-sm text-gray-500">
                    ${(pkg.price / pkg.outputs).toFixed(2)} per separation
                  </p>
                </div>

                <StripeBuyButton
                  buttonId={pkg.id}
                  publishableKey={STRIPE_CONFIG.publishableKey}
                  className="w-full"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="max-w-5xl mx-auto">
          <h2 className="text-2xl font-bold text-gray-900 text-center mb-8">
            Why Choose Our Service?
          </h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div
                key={index}
                className="bg-white rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="w-12 h-12 bg-indigo-100 rounded-lg flex items-center justify-center text-indigo-600 mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditsPage;